import styled from 'styled-components'

import { H2 as H2Base } from 'components//Styled/Typography'
import ContainerBase from 'components/Styled/Container'

export const Content = styled.div`
  display: grid;
  padding: 6rem 0 10rem;
  align-items: center;
  grid-template-areas:
    'heading code'
    'button code';

  ${props => props.theme.mq.medium.css`
    grid-template-areas: "code"
                         "heading"
                         "button";
  `}

  ${props => props.theme.mq.small.css`
    display: flex;
    flex-direction: column;
    padding: 8rem 0;
  `}
`

export const H2 = styled(H2Base)`
  grid-area: heading;
  margin: 0 0 8rem;
`

export const Code = styled.h3`
  grid-area: code;
  font-size: 30rem;
  font-weight: bold;
  margin: 0 7rem 0 10rem;
  white-space: nowrap;
  color: #cfccd2;
  line-height: 1;

  span {
    opacity: 0.36;
  }

  strong {
    display: inline-block;
    transform: translateY(-8%) rotateX(180deg);
    vertical-align: baseline;
    font-weight: inherit;
  }

  ${props => props.theme.mq.small.css`
    display: none;
  `}

  ${props => props.theme.mq.medium.css`
    margin: 0;
    font-size: 20rem;
  `}
`

export const ButtonContainer = styled.div`
  grid-area: button;

  ${props => props.theme.mq.small.css`
    width: 100%;
    margin-top: 10rem;
  `}
`

export const Container = styled(ContainerBase)`
  margin: auto;
`

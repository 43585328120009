import React from 'react'
import Props from 'prop-types'

import Page from 'components/Page'
import Navigation from 'components/Navigation'
import Button from 'components/Styled/Button'
import { Container, Content, ButtonContainer, Code, H2 } from './styled'

const NotFound = ({ translates }) => (
  <Page title="Not Found" translates={translates}>
    <Navigation isMobileVisible translates={translates} />

    <Container>
      <Content>
        <Code>
          <span>40</span>
          <strong>4</strong>
        </Code>
        <H2 as="h3">The page you were looking for doesn’t exist</H2>
        <ButtonContainer>
          <Button>Go Back</Button>
        </ButtonContainer>
      </Content>
    </Container>
  </Page>
)

NotFound.propTypes = {
  translates: Props.any.isRequired,
}

export default NotFound

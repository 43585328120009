import styled from 'styled-components'

const Button = styled.button`
  display: inline-block;
  width: 13em;
  padding: 0.9em 1.5em;
  margin: 0 3.2rem 0 0;
  font: inherit;
  font-size: 1.8rem;
  text-transform: uppercase;
  font-weight: bold;
  color: ${props => (props.inverted ? props.theme.colors.primary : 'white')};
  border-radius: 5em;
  border: 2px solid
    ${props => (props.inverted ? 'white' : props.theme.colors.primary)};
  appearance: none;
  cursor: pointer;
  transition: color 0.3s, background 0.3s;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-decoration: none;
  text-align: center;
  background: ${props =>
    props.inverted ? 'white' : props.theme.colors.primary};

  &:hover,
  &:active {
    color: ${props => (props.inverted ? 'white' : props.theme.colors.primary)};
    background: ${props =>
      props.inverted ? props.theme.colors.primary : 'white'};
  }

  svg {
    display: inline-block;
    vertical-align: middle;
    margin: -0.2em 0.5em 0 0;
    font-size: 1.3em;
  }

  ${props => props.theme.mq.small.css`
    width: 100%;
    margin: 1rem 0;
  `}
`

export default Button

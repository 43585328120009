import React from 'react'
import NotFound from 'components/404'

// eslint-disable-next-line react/prop-types
const NotFound404 = ({ pageContext: { lang } }) => (
  // eslint-disable-next-line react/prop-types
  <NotFound translates={lang} />
)

export default NotFound404
